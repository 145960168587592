import React from "react";

import { Heading, Text } from "@chakra-ui/react";

import Footer from "../components/Footer";
import Framed from "../components/Framed";
import Header from "../components/Header";
import { SpecificHeaders } from "../components/SocialHeaders";
import { useHeaderImage } from "../components/images/fishMarketHeader";

export default function ContactSuccessPage() {
  return (
    <>
      <Header imageHook={useHeaderImage}>
        <SpecificHeaders title="Contact Us" path="/contact-success" />
        <Heading as="p">Contact Us</Heading>
      </Header>
      <Framed
        as="main"
        id="main"
        px={[0, null, null, "276px"]}
        py="90px"
        textStyle="form"
      >
        <Heading>Thank You</Heading>
        <Text>Your message has been sent.</Text>
      </Framed>
      <Footer />
    </>
  );
}
